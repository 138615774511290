<template>
  <div>
    <!-- 面包屑导航 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>产品管理</el-breadcrumb-item>
      <el-breadcrumb-item>产品分类</el-breadcrumb-item>
    </el-breadcrumb>
    <!-- 视图区 -->
    <el-card>
      <el-button type="primary"
                 @click="showAddCate"
                 class="addbtn">添加分类</el-button>
      <!-- 分类列表 -->
      <el-table :data="cateList"
                style="width: 100%; margin-bottom: 20px;"
                row-key="id"
                stripe
                default-expand-all
                :tree-props="{children: 'children'}">
        <el-table-column type="index"></el-table-column>
        <el-table-column prop="catname"
                         label="分类名称"></el-table-column>
        <el-table-column prop="level"
                         label="等级">
          <template slot-scope="scope">
            <el-tag size="mini"
                    v-if="scope.row.level === 0">一级</el-tag>
            <el-tag type="success"
                    size="mini"
                    v-else-if="scope.row.level === 1">二级</el-tag>
            <el-tag type="wraning"
                    size="mini"
                    v-else>三级</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="status"
                         label="是否启用">
          <template slot-scope="scope">
            <el-switch v-model="scope.row.status"
                       :active-value="1"
                       :inactive-value="0"
                       @change="stateChange(scope.row)"></el-switch>
          </template>
        </el-table-column>
        <el-table-column prop="sort"
                         label="排序"
                         width="250">
          <template slot-scope="scope">
            <el-input size="mini"
                      v-model="scope.row.sort"
                      @blur="sortChange(scope.row)"
                      style="width: 60px;"></el-input>
          </template>
        </el-table-column>
        <el-table-column prop="update_time"
                         label="更新时间"></el-table-column>
        <el-table-column label="操作"
                         width="200">
          <template slot-scope="scope">
            <el-button type="primary"
                       icon="el-icon-edit"
                       size="mini"
                       @click="showEditDialog(scope.row.id)">编辑</el-button>
            <el-button type="warning"
                       icon="el-icon-delete"
                       size="mini"
                       v-if="scope.row.status === 0"
                       @click="deleteById(scope.row.id)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-card>

    <!-- 添加编辑表单 -->
    <el-dialog :title="titleMap[dialogTitle]"
               :visible.sync="dialogVisible"
               :close-on-click-modal="false"
               width="50%"
               @close="addDialogClose">
      <!-- 添加分类表单区 -->
      <el-form :model="cateForm"
               :rules="cateFormRules"
               ref="cateFormRef"
               label-position="top">
        <el-form-item label="分类名称："
                      prop="catname">
          <el-input v-model="cateForm.catname"></el-input>
        </el-form-item>

        <el-form-item label="分类描述：">
          <el-input type="textarea"
                    :rows="3"
                    resize="none"
                    v-model="cateForm.content"></el-input>
        </el-form-item>

        <!-- 分类选择 -->
        <el-form-item label="上级分类：">
          <el-cascader v-model="selectedKeys"
                       :options="parentCateDate"
                       :props="{ expandTrigger: 'hover', value: 'id', label: 'catname', children: 'children', checkStrictly: true }"
                       :placeholder="placeholder"
                       clearable
                       @change="handleCheckChange"></el-cascader>
        </el-form-item>

        <!-- 图片上传  -->
        <el-form-item label="图片上传：*只能上传jpg/png文件，且不超过500kb"
                      prop="logo">
          <el-upload :data="imgtype"
                     :action="uploadURL"
                     :headers="headerObj"
                     :limit="1"
                     :on-preview="handlePreview"
                     :before-upload="beforeUpload"
                     :on-success="handleSuccess"
                     :file-list="fileList"
                     list-type="picture">
            <el-button size="small"
                       type="primary">点击上传</el-button>
          </el-upload>
          <el-dialog :visible.sync="imgDialogVisible"
                     width="50%"
                     append-to-body>
            <img width="100%"
                 :src="dialogImageUrl"
                 alt="">
          </el-dialog>
        </el-form-item>
      </el-form>
      <span slot="footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary"
                   @click="upDate">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data () {
    return {

      cateList: [],
      titleMap: {
        addData: '添加产品分类',
        updateData: '修改产品分类'
      },
      dialogTitle: '',

      dialogVisible: false,
      // 添加分类的表单数据对象
      cateForm: {
        catname: '',
        pid: 0,
        content: '',
        logo: '',
        level: 0
      },
      cateFormRules: {
        catname: [
          { required: true, message: '请输入产品分类名称', trigger: 'blur' },
          { max: 30, message: '长度小于 50 个字符', trigger: 'blur' }
        ]
      },
      // 父级分类列表信息
      parentCateDate: [],
      // 选中的父级数组
      selectedKeys: [],

      placeholder: '请选择所属分类，默认为顶级分类',

      // 图片上传地址
      uploadURL: this.globalUrlapi + 'logo',
      headerObj: {
        Authorization: window.sessionStorage.getItem('token')
      },
      imgtype: {
        type: 'category'
      },
      fileList: [],
      imgDialogVisible: false,
      dialogImageUrl: ''
    }
  },

  created () {
    this.getCateList()
  },

  methods: {
    async getCateList () {
      const { data: res } = await this.$http.get('categorys')
      if (res.code !== 200) {
        return this.$message.error('网络延时，请重新刷新网页重试...')
      }
      this.cateList = res.data
    },

    // 改变状态
    async stateChange (userinfo) {
      const { data: res } = await this.$http.put(`categorys/${userinfo.id}?status=${userinfo.status}`)
      if (res.code !== 200) {
        userinfo.status = !userinfo.status
        return this.$message.error('更新状态失败！')
      }
      this.$message.success('更新状态成功！')
      // 刷新列表
      this.getCateList()
    },

    // 排序
    async sortChange (userinfo) {
      const { data: res } = await this.$http.put(`categorys/${userinfo.id}?sort=${userinfo.sort}`)
      if (res.code !== 200) {
        userinfo.sort = this.userinfo.sort
        return this.$message.error('更新状态失败！')
      }
      this.$message.success('更新状态成功！')
      // 刷新列表
      this.getCateList()
    },

    // 添加分类
    showAddCate () {
      this.getParentCateList()
      this.dialogVisible = true
      this.dialogTitle = 'addData'
    },

    // 编辑分类
    async showEditDialog (id) {
      const { data: res } = await this.$http.get('categorys/' + id)
      if (res.code !== 200) {
        return this.$message.error('网络延时，请刷新页面重试...')
      }
      this.cateForm = res.data
      this.fileList.push({ name: res.data.logo, url: this.globalUrlapi + res.data.logo })
      this.selectedKeys = res.data.pid
      this.dialogVisible = true
      this.getParentCateList()
      this.dialogTitle = 'updateData'
    },

    // 添加时获取父级分类数据
    async getParentCateList () {
      const { data: res } = await this.$http.get('categorys')
      if (res.code !== 200) {
        return this.$message.error('网络延时，请刷新页面重试...')
      }
      this.parentCateDate = res.data
    },

    // 分类选择器触发函数
    handleCheckChange () {
      // 如果 this.selectedKeys 数组中的 length 大于0，则选中父级分类
      // 反之，就说明没有选中任何父级分类
      if (this.selectedKeys.length > 0) {
        this.cateForm.pid = this.selectedKeys[this.selectedKeys.length - 1]
        this.cateForm.level = this.selectedKeys.length
      } else {
        this.cateForm.pid = 0
        this.cateForm.level = 0
      }
    },

    // 关闭时弹窗，清空数据
    addDialogClose () {
      this.$refs.cateFormRef.resetFields()
      this.cateForm.content = ''
      this.selectedKeys = []
      this.cateForm.level = 0
      this.cateForm.pid = 0
      this.fileList = []
    },

    // 查看图片
    handlePreview (fileList) {
      this.dialogImageUrl = fileList.url
      this.imgDialogVisible = true
    },

    // 图片上传前校验
    beforeUpload (file) {
      const isIMAGE = file.type === 'image/jpg' || file.type === 'image/jpeg' || file.type === 'image/png'
      const isLt1M = file.size / 1024 / 500 < 1
      if (!isIMAGE) {
        this.$message.error('上传文件只能是 jpg 或 png 图片格式!')
      }
      if (!isLt1M) {
        this.$message.error('上传文件大小不能超过 500kb!')
      }
      return isIMAGE && isLt1M
    },

    // 图片上传成功回调
    handleSuccess (response) {
      this.cateForm.logo = response.data
    },

    upDate () {
      // 添加请求
      if (this.dialogTitle === 'addData') {
        this.$refs.cateFormRef.validate(async valid => {
          if (!valid) return
          const { data: res } = await this.$http.post('categorys', this.cateForm)
          if (res.code !== 200) {
            return this.$message.error('网络延时，请刷新页面重试...')
          }
          this.$message.success('添加成功！')
          this.getCateList()
          this.dialogVisible = false
        })
      } else {
        // 修改请求
        this.$refs.cateFormRef.validate(async valid => {
          if (!valid) return
          const { data: res } = await this.$http.put('categorys/' + this.cateForm.id, {
            catname: this.cateForm.catname,
            content: this.cateForm.content,
            pid: this.cateForm.pid,
            logo: this.cateForm.logo,
            level: this.cateForm.level
          })
          if (res.code !== 200) {
            return this.$message.error('网络延时，请刷新页面重试...')
          }
          this.$message.success('修改成功！')
          // 隐藏对话框
          this.dialogVisible = false
          this.getCateList()
        })
      }
    },

    // 删除分类信息
    async deleteById (id) {
      const confirmResult = await this.$confirm('您确定要删除这个产品分类吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }
      ).catch(err => err)

      // 如果用户取消了删除
      if (confirmResult !== 'confirm') {
        return this.$message.info('已取消删除！')
      }

      const { data: res } = await this.$http.delete('categorys/' + id, {
        headers: { Authorization: localStorage.getItem('token') }
      })
      if (res.code !== 200) {
        return this.$message.error('删除失败，请重试...')
      }
      this.$message.success('删除成功！')
      this.getCateList()
    }
  }
}
</script>

<style scoped>
.el-cascader {
  width: 100%;
}

.mycascader .el-radio__inner {
  top: -18px;
  left: -19px;
  border-radius: 0;
  border: 0;
  width: 170px;
  height: 34px;
  background-color: transparent;
  cursor: pointer;
  box-sizing: border-box;
  position: absolute;
}

.mycascader .el-radio__input.is-checked .el-radio__inner {
  background: transparent;
}
</style>
